import { getStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
const navigation = require('react-navigation');

interface ApiPayload{
    method: string;
    contentType?: string;
    body?: object | string;
    endPoint: string;
    type?: string;
}
interface ImgVideoPayload {
    filename: string;
    id: number;
    type: string;
    url: string;
}

interface CreatorDataPayload {
    full_name: string;
    profile_url: string | null;
    id: number;
}

interface PostAttributesPayload {
    id: number;
    description: string | null;
    name: string | null;
    location: string | null;
    body: string;
    active_message: boolean;
    account_id: number;
    created_at: string;
    updated_at: string;
    creator_details: CreatorDataPayload;
    images_and_videos: ImgVideoPayload[];
    comment_count: number;
    is_following_seller: boolean;
    like_count: number;
    is_liked: boolean;
}

export interface PostDataPayloadType {
    id: string;
    attributes: PostAttributesPayload;
    type: string;
}
interface PostApiResponsePayload {
    data: PostDataPayloadType[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isAppLoader: boolean;
    sellerPostData: PostDataPayloadType[];
    buyerPostLoader: boolean;
    feedPageNo: number;
    isFeedLoader: boolean;
    islatestPost: boolean;
    hasMorePosts: boolean;
    isSideBar: boolean;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class SellerFeedController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSellerPostDataApiCallId : string = "";
    sellerPostContainerRef: React.RefObject<HTMLDivElement> = createRef();
    postToggleLikeUnlikeApiCallID : string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isAppLoader: false,
            feedPageNo: 1,
            buyerPostLoader: false,
            sellerPostData: [],
            isFeedLoader: false,
            hasMorePosts: true,
            islatestPost: false,
            isSideBar: true
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJSON = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (responseJSON.status === 500) {
                this.showAlert("Error", "Internal Server Error!!");
                this.setState({ isAppLoader: false });
                return;
            }
            if (responseJSON && !responseJSON.errors) {
              if(this.getSellerPostDataApiCallId === apiRequestId){
                this.sellerPostDataSuccessCallBack(responseJSON);
              }
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        this.getSellerPostListingData();
        if (this.sellerPostContainerRef.current) {
            this.sellerPostContainerRef.current.addEventListener('scroll', this.handleScroll);
        }
    };

    handleScroll = () =>{
        if (this.sellerPostContainerRef.current) {
            const container = this.sellerPostContainerRef.current;
            if (
                container.scrollTop + container.clientHeight >= container.scrollHeight -10  &&  !this.state.isAppLoader && this.state.hasMorePosts
            ) {
                this.getSellerPostListingData(true); 
            }
        }
    };

    getSellerPostListingData = async (isFromBottomPagination = false) => {
        if (isFromBottomPagination) {
            this.setState({ isFeedLoader: true });
        }

        this.getSellerPostDataApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getPostListingDataEndPoint + `?page=${this.state.feedPageNo}&per_page=5`,
            body: '',
            type: ''
        });
    };

    apiCall = async (apiRequestData: ApiPayload) => {
        const { contentType, method, endPoint, body, type } = apiRequestData;
        let token = await getStorageData("singupLogin");
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestApiMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestApiMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
        return requestApiMessage.messageId;
    };

    sellerPostDataSuccessCallBack = (responseData: PostApiResponsePayload) => {
        if (responseData && responseData.data) {
            if(responseData.data.length === 0){
                this.setState({hasMorePosts: false});
            } else{
                this.setState((prevState)=> ({
                    buyerPostLoader: false,
                    islatestPost: false,
                    sellerPostData: [ ...prevState.sellerPostData, ...responseData.data ], 
                    feedPageNo: prevState.feedPageNo + 1,
                    isFeedLoader: false,
                  }));
            }
          }
    };

    toggleSideBar = () => {
        this.setState({isSideBar: !this.state.isSideBar});
    };

    handlePostLikeUnlike = (postId: string) => {
        const updatedPostData = [...this.state.sellerPostData];
        const postIndex = updatedPostData.findIndex(post => post.id === postId);

        if (postIndex !== -1) {
            const isLiked = updatedPostData[postIndex].attributes.is_liked;
            const likeCnt = updatedPostData[postIndex].attributes.like_count;
            if (isLiked === true) {
                updatedPostData[postIndex].attributes.is_liked = false;
                if (likeCnt > 0) {
                    updatedPostData[postIndex].attributes.like_count = likeCnt - 1;
                }
                this.setState({ sellerPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            } else {
                updatedPostData[postIndex].attributes.is_liked = true;
                updatedPostData[postIndex].attributes.like_count = likeCnt + 1;
                this.setState({ sellerPostData: updatedPostData }, () => {
                    this.postApiCallFn(postId);
                });
            }
        }
    };

    postApiCallFn = async (postId: string) => {
        this.postToggleLikeUnlikeApiCallID = await this.apiCall({
            method: configJSON.PostAPiMethod,
            contentType: configJSON.validationApiContentType,
            endPoint: configJSON.postLikeUnlikeEndPoint,
            body: {
                "data": {
                    "attributes": {
                        "likeable_id": Number(postId),
                        "likeable_type": "BxBlockPosts::Post"
                    }
                }
            },
            type: ''
        })
    };
    // Customizable Area End
}