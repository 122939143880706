import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    IconButton,
    Typography,
    Avatar,
    Button,
    CircularProgress,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { avatarImg, storyInfo } from "./assets";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PostCard from "../../../components/src/PostCard.web";
import SellerFeedController, { configJSON, Props, PostDataPayloadType } from "./SellerFeedController.web";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { hamburgerIcon, searchModalIcon } from "../../LiveStreaming/src/assets";
import { calendarIc, notificationIc } from "../../landingpage/src/assets";
// Customizable Area End

export default class SellerFeed extends SellerFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <SellerFeedWrapper>
                <Loader loading={this.state.isAppLoader} />
                <Box className="mainContainer">
                    <Box>
                        <div className="dashboard-outer">
                            <div className="dashboard-inner">
                                <SellerSidebar
                                    navigation={""}
                                    id={""}
                                    classes={undefined}
                                    isOpen={this.state.isSideBar}
                                    data-test-id="sellerSidebar"
                                    activetabName="feed"
                                    closeModal={this.toggleSideBar}
                                />
                            </div>
                        </div>
                    </Box>
                    <Box className="mainRightContainer">
                        <Box>
                            <Box className={"spaceBetween"}>
                                <Box className={"backBtnNavigation"} m={0}>
                                    <Typography className={"mainPageTitle"} data-test-id="inventoryTxtId">{configJSON.feedTxt}</Typography>
                                </Box>
                                <Box component='span' className={"flexContainer"}>
                                    <ModalSearch className="inventorySearch">
                                        <img src={searchModalIcon} alt="search" />
                                        <input type="text" placeholder={"Search for users..."} data-test-id="inventorySearchTestID" />
                                    </ModalSearch>
                                    <Box component="span" className={"headerUpIcons"}><img src={calendarIc} alt="calendarIcon" /></Box>
                                    <Box component="span" className={"headerUpIcons"}><img src={notificationIc} alt="bellIcon" /></Box>
                                    <Box className="hideSideBarHamburgerSection headerUpIcons">
                                        <IconButton data-test-id="hamburger-btn" onClick={() => this.toggleSideBar()}>
                                            <img src={hamburgerIcon} alt="hamburger" />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="postSuggestionFlex">
                            <Box className="postMiddleContainer">
                                <Box className="storyMainContainer">
                                    <IconButton className="arrowPrev">
                                        <ArrowBackIos />
                                    </IconButton>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((__, storiesIndex) => (
                                        <Box className="feedButton" key={storiesIndex}>
                                            <Box className="StoryBoxBorder">
                                                <img className="story" src={storyInfo} />
                                            </Box>
                                        </Box>
                                    ))}
                                    <IconButton className="arrowNext">
                                        <ArrowForwardIos />
                                    </IconButton>
                                </Box>
                                <PostListContainer>
                                    <div className="postMainBox" ref={this.sellerPostContainerRef}>
                                        <Box className="postInnerContainer">
                                            {this.state.sellerPostData.map((postData: PostDataPayloadType, postIndex: number) => {
                                                return (
                                                    <PostCard
                                                        item={postData}
                                                        postKeyId={postIndex}
                                                        postId={postData.id}
                                                        handleToggleLikePost={this.handlePostLikeUnlike}
                                                        data-test-id={`postCardComponentTestId${postIndex}`}
                                                    />
                                                )
                                            })}
                                            {(this.state.isFeedLoader && this.state.hasMorePosts) ? <CircularProgress /> : ''}
                                        </Box>
                                    </div>
                                </PostListContainer>
                            </Box>

                            <Box className="suggestionMainBoxContainer">
                                <Typography className="suggestionHeading">Suggested for you</Typography>
                                <Box className="suggestionMainContainer">
                                    {[1, 2, 3, 4, 5, 6].map((__, itemIndex) => (
                                        <Box className="suggUserInfoBox" key={itemIndex}>
                                            <Box className="sugLeftBox">
                                                <Box className="avatarSuggestionBox">
                                                    <Avatar src={avatarImg} className="suggestionAvatar" />
                                                </Box>
                                                <Box className="suggestionInfoBox">
                                                    <Typography className="userNameText">QuantumLeap77</Typography>
                                                    <Typography className="userNameFull">Quantum Leap</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="followBox">
                                                <Button className="followBtn">Unfollow</Button>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SellerFeedWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const SellerFeedWrapper = styled(Box)({
    marginTop: "104px",
    "@media(max-width: 1150px)": {
        marginTop: "71px",
    },
    "& .mainContainer": {
        display: "flex",
        width:"100%"
    },
    "& .mainRightContainer":{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        padding:"24px"
    },
    "& .postSuggestionFlex":{
        display:"flex",
        width:"100%"
    },
    "& .postMiddleContainer": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
    },
    "& .suggestionMainBoxContainer": {
        width: "100%",
        borderLeft: "1px solid #44444433",
        marginTop: "30px",
        maxWidth: "410px",
        paddingRight: "60px",
        paddingLeft: "24px",
        "@media(max-width:992px)": {
            display: "none"
        },
    },
    "& .storyMainContainer": {
        width: "100%",
        display: "flex",
        position: "relative",
        overflowX: "scroll",
        padding: "0 20px 0 0",
        marginLeft: "32px",
        marginTop: "40px",
        scrollBehavior: "smooth",
        paddingBottom: "12px",
        marginBottom: "20px",
        "&::-webkit-scrollbar": {
            height: "4px",
        },
        "&::-webkit-scrollbar-track": {
            background: "#D9D9D955",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#D9D9D9",
        },
    },
    "& .feedButton": {
        cursor: "pointer",
        margin: "0 24px 0 0",
    },
    "& .StoryBoxBorder": {
        height: "74px",
        width: "74px",
        display: "flex",
        borderRadius: "60px",
        background: "linear-gradient(130.56deg, #EB3AA7 9.42%, #6649D9 87%)",
        padding: "2.46px",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .story": {
        padding: "4.07px",
        borderRadius: "60px",
        width: "70px",
        height: "70px",
        background: "#FFFFFF",
    },
    "& .arrowPrev, & .arrowNext": {
        left: "0",
        position: "sticky",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "28px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px"
    },
    "& .arrowNext": {
        right: "0",
    },
    "& .suggestionHeading": {
        fontSize: "16px",
        color: "#000000",
        fontWeight: 500,
    },
    "& .userNameText": {
        fontWeight: 500,
        fontSize: "14px",
        color: "#444444",
    },
    "& .userNameFull": {
        fontWeight: 400,
        color: "#44444480",
        marginTop: "4px",
        fontSize: "14px",
    },
    "& .suggUserInfoBox": {
        display: "flex",
        alignItems: "center",
        marginTop: "16px",
        justifyContent: "space-between",
    },
    "& .sugLeftBox": {
        gap: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .followBtn": {
        border: "1px solid #F5EA16",
        textTransform: "none",
        padding: "6px 16px",
        borderRadius: "50px",
    },
    "& .hideSideBarHamburgerSection": {
        display: "none",
        "@media(max-width: 991px)": {
            display: "block",
        }
    },
    "& .headerUpIcons": {
        width: '48px',
        borderRadius: '10px',
        height: '48px',
        background: 'var(--0, #FFF)',
        border: '1px solid var(--0, #FFF)',
        display: "flex",
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        margin: "0em 0.5em",
    },
    "& .mainPageTitle": {
        fontSize: '20px',
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontStyle: 'normal',
        marginLeft: "0.5em",
        lineHeight: 'normal',
    },
    "& .spaceBetween": {
        justifyContent: "space-between",
        display: "flex",
    },
    "& .backBtnNavigation": {
        alignItems: "center",
        display: "flex",
    },
    "& .flexContainer": {
        display: "flex"
    },
});
const PostListContainer = styled(Box)({
    "& .postMainBox": {
        overflowY: "auto",
        scrollBehavior: "smooth",
        height: "calc(100vh - 152px)",
        width:"100%",
    },
    "& .postInnerContainer": {
        width: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
});

const ModalSearch = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "12px",
    gap: "12px",
    borderRadius: "12px",
    boxShadow: "0 0 25px rgba(0,0,0,0.2)",
    "&.inventorySearch": {
        margin: "0 0.5em",
        width: "482px",
        height: "48px",
        "@media(max-width: 1200px)": {
            width: "100%",
            maxWidth: "460px",
        }
    },
    "& img": {
        cursor: "pointer"
    },
    "& input": {
        border: "none",
        outline: "none",
        flex: 1
    },
    "& .inventoryFilter": {
        borderLeft: "1px solid rgba(68, 68, 68, 0.1)",
        paddingLeft: "12px"
    }
});
// Customizable Area End
