import { getStorageData } from "framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
const navigation = require('react-navigation');
interface ApiPayloadType{
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object | string;
    type?: string;
}
interface ImageOrVideo {
    id: number;
    filename: string;
    url: string;
    type: string;
}

interface CreatorDetails {
    id: number;
    full_name: string;
    profile_url: string | null;
}

interface PostAttributes {
    id: number;
    name: string | null;
    description: string | null;
    body: string;
    location: string | null;
    account_id: number;
    active_message: boolean;
    created_at: string;
    updated_at: string;
    images_and_videos: ImageOrVideo[];
    creator_details: CreatorDetails;
    like_count: number;
    is_liked: boolean;
    comment_count: number;
    is_following_seller: boolean;
}

export interface PostDataPayload {
    id: string;
    type: string;
    attributes: PostAttributes;
}
interface PostApiData {
    data: PostDataPayload[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    categoriesData : Array<string>;
    showComingSoon: boolean;
    currentIndexMap: { [key: number]: number };
    currentProductIndex: number;
    buyerPostData: PostDataPayload[];
    buyerPostLoader: boolean;
    isNotification_Checked: boolean;
    isAllRead: boolean;
    feedPageNo: number;
    isFeedLoader: boolean;
    islatestPost: boolean;
    hasMorePosts: boolean;
    tokenPresentOrNot: string | null;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class BuyerFeedController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getPostDataApiCallId : string = "";
    postContainerRef: React.RefObject<HTMLDivElement> = createRef();
    postLikeUnlikeApiCallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            categoriesData: ['Furniture & Interior ', 'Antiques ', 'Sports', 'Toys', 'Beauty & Make up', 'Sneakers '],
            buyerPostLoader: false,
            currentProductIndex: 0,
            isNotification_Checked: false,
            isAllRead: false,
            feedPageNo: 1,
            isFeedLoader: false,
            buyerPostData: [],
            showComingSoon: false,
            currentIndexMap: {},
            islatestPost: false,
            hasMorePosts: true,
            tokenPresentOrNot: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let apiResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
            if (apiResponseJson.status === 500) {
                this.showAlert("Error", "Internal Server Error!!");
                this.setState({ isLoading: false });
                return;
            }
            if (apiResponseJson && !apiResponseJson.errors) {
              if(this.getPostDataApiCallId === apiRequestId){
                this.postListingDataSuccessCallback(apiResponseJson);
              }
            }
        }
        // Customizable Area End
    }


    // Customizable Area Start
    async componentDidMount() {
        let buyerToken = await getStorageData("buerLoginToken");
        this.setState({ tokenPresentOrNot: buyerToken });
        this.checkTokenPresentOrNot();
        this.getPostListingData();
        if (this.postContainerRef.current) {
            this.postContainerRef.current.addEventListener('scroll', this.handleScroll);
        }
    };

    checkTokenPresentOrNot = () => {
        if (this.state.tokenPresentOrNot === null) {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "GetStartedLogin");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message)
        }
    };

    handleScroll = () =>{
        if (this.postContainerRef.current) {
            const container = this.postContainerRef.current;
            if (
                container.scrollTop + container.clientHeight >= container.scrollHeight -10  &&  !this.state.isLoading && this.state.hasMorePosts
            ) {
                this.getPostListingData(true); 
            }
        }
    };

    getPostListingData = async (isFromPagination = false) => {
        if (isFromPagination) {
            this.setState({ isFeedLoader: true });
        }

        this.getPostDataApiCallId = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.getPostListingDataEndPoint + `?page=${this.state.feedPageNo}&per_page=5`,
            body: '',
            type: ''
        });
    };

    apiCall = async (apiReqData: ApiPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiReqData;
        let token = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": contentType,
            token: token,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    postListingDataSuccessCallback = (responseJson: PostApiData) => {
        if (responseJson && responseJson.data) {
            if(responseJson.data.length === 0){
                this.setState({hasMorePosts: false});
            } else{
                this.setState((prevState)=> ({
                    buyerPostData: [ ...prevState.buyerPostData, ...responseJson.data ], 
                    buyerPostLoader: false,
                    isFeedLoader: false,
                    islatestPost: false,
                    feedPageNo: prevState.feedPageNo + 1,
                  }));
            }
          }
    };

    handleToggleLikePost = (postId: string) => {
        const newData = [...this.state.buyerPostData];
        const postIndex = newData.findIndex(post => post.id === postId);

        if (postIndex !== -1) {
            const isLikedOrNot = newData[postIndex].attributes.is_liked;
            const likeCount = newData[postIndex].attributes.like_count;
            if (isLikedOrNot === true) {
                newData[postIndex].attributes.is_liked = false;
                if (likeCount > 0) {
                    newData[postIndex].attributes.like_count = likeCount - 1;
                }
                this.setState({ buyerPostData: newData }, () =>{
                    this.postApiCallFn(postId);
                });
            } else {
                newData[postIndex].attributes.is_liked = true;
                newData[postIndex].attributes.like_count = likeCount + 1;
                this.setState({ buyerPostData: newData }, () => {
                    this.postApiCallFn(postId);
                });
            }
        }
    };

    postApiCallFn = async (postId: string) => {
        this.postLikeUnlikeApiCallID = await this.apiCall({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PostAPiMethod,
            endPoint: configJSON.postLikeUnlikeEndPoint,
            body: {
                "data": {
                    "attributes": {
                        "likeable_id": Number(postId),
                        "likeable_type": "BxBlockPosts::Post"
                    }
                }
            },
            type: ''
        })
    };
    // Customizable Area End
}