import React from "react";
// Customizable Area Start
import {
    Box,
    styled,
    IconButton,
    Typography,
    Avatar,
    Button,
    CircularProgress,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { arrowDownIcon, avatarImg, storyInfo } from "./assets";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import PostCard from "../../../components/src/PostCard.web";
import BuyerFeedController, { configJSON, Props, PostDataPayload } from "./BuyerFeedController.web";
// Customizable Area End



export default class BuyerFeed extends BuyerFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <PostListingStyleWrapper>
                <Loader loading={this.state.isLoading} />
                <Box className="mainContainer">
                    <Box className="gridBox">
                        <Box className="headerCategoryBox">
                            <Typography className="categoryTxt">{configJSON.categoriesTxt}</Typography>
                        </Box>
                        <Box className="categoriesBox">
                            {this.state.categoriesData.map((category, index) => (
                                <Box className="categoryInnerBox" key={index}>
                                    <Box>
                                        <Typography className="categoryTitleTxt">{category}</Typography>
                                    </Box>
                                    <Box>
                                        <img src={arrowDownIcon} />
                                    </Box>
                                </Box>
                            ))}
                            
                        </Box>
                    </Box>
                    <Box className="middleGridBox">
                        <Box className="storyViewContainer"> 
                            <IconButton className="leftArrow">
                                <ArrowBackIos />
                            </IconButton>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((__, storiesIndex) => (
                                <Box className="feedBtn" key={storiesIndex}>
                                    <Box className="storyBorder">
                                        <img className="story" src={storyInfo} />
                                    </Box>
                                </Box>
                            ))}
                            <IconButton className="rightArrow">
                                <ArrowForwardIos />
                            </IconButton>
                        </Box>
                        <PostListContainer>
                            <div className="postMainContainer" ref={this.postContainerRef}>
                                <Box className="postContainer">
                                    {this.state.buyerPostData.map((postData: PostDataPayload, postIndex: number) => {
                                        return (
                                            <PostCard
                                                data-test-id={`postCardCompTestId${postIndex}`}
                                                item={postData}
                                                postKeyId={postIndex}
                                                postId={postData.id}
                                                handleToggleLikePost={this.handleToggleLikePost}
                                            />
                                        )
                                    })}
                                    {(this.state.isFeedLoader && this.state.hasMorePosts ) ? <CircularProgress />: ''}
                                </Box>
                            </div>
                        </PostListContainer>
                    </Box>

                    <Box className="suggestionBox">
                        <Typography className="suggestionForTxt">Suggested for you</Typography>
                        <Box className="suggestionMainContainer">
                            {[1, 2, 3, 4, 5, 6].map((__, itemIndex) => (
                                <Box className="suggestedUserBox" key={itemIndex}>
                                    <Box className="suggestionLeftBox">
                                        <Box className="avatarSuggestionBox">
                                            <Avatar src={avatarImg} className="suggestionAvatar" />
                                        </Box>
                                        <Box className="suggestionInfoBox">
                                            <Typography className="userNameTxt">QuantumLeap77</Typography>
                                            <Typography className="userFullNameTxt">Quantum Leap</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="followBox">
                                        <Button className="followUnfollowBtn">Unfollow</Button>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </PostListingStyleWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const PostListingStyleWrapper = styled(Box)({
    marginTop: "104px",
    "@media(max-width: 1150px)": {
        marginTop: "71px",
    },
    "& .mainContainer":{
        display:"flex"
    },
    "& .gridBox": {
        width: "100%",
        maxWidth:"244px",
        borderRight:"3px solid #D9D9D94D",
        padding:"0px 20px",
        "@media(max-width:700px)": {
            display:"none"
        },
    },
    "& .middleGridBox": {
        width: "100%",
        maxWidth:"1000px",
        overflowX:"hidden",
        display: "flex",
        flexDirection: "column"
    },
    "& .suggestionBox": {
        borderLeft: "1px solid #44444433",
        width: "100%",
        maxWidth:"440px",
        marginTop: "30px",
        paddingLeft: "24px",
        paddingRight: "60px",
        "@media(max-width:800px)": {
            display:"none"
        },
    },
    "& .storyViewContainer": {
        width:"100%",
        display: "flex",
        padding: "0 20px 0 0",
        overflowX: "scroll",
        marginTop: "46px",
        marginLeft: "32px",
        paddingBottom: "12px",
        scrollBehavior: "smooth",
        position: "relative",
        marginBottom: "20px",
        "&::-webkit-scrollbar": {
            height: "4px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#D9D9D955"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#D9D9D9",
            borderRadius: "10px",
        },
    },
    "& .feedBtn": {
        margin: "0 24px 0 0",
        cursor: "pointer",
    },
    "& .storyBorder": {
        width: "74px",
        height: "74px",
        borderRadius: "60px",
        display: "flex",
        padding: "2.46px",
        background: "linear-gradient(130.56deg, #EB3AA7 9.42%, #6649D9 87%)",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .story": {
        borderRadius: "60px",
        padding: "4.07px",
        background: "#FFFFFF",
        width: "70px",
        height: "70px",
    },
    "& .leftArrow, & .rightArrow": {
        left: "0",
        position: "sticky",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1,
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "28px",
        height: "28px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "4px"
    },
    "& .rightArrow": {
        right: "0",
    },
    "& .suggestionForTxt": {
        fontWeight: 500,
        fontSize: "16px",
        color: "#000000",
    },
    "& .userNameTxt": {
        color: "#444444",
        fontWeight: 500,
        fontSize: "14px",
    },
    "& .userFullNameTxt": {
        color: "#44444480",
        fontWeight: 400,
        fontSize: "14px",
        marginTop: "4px"
    },
    "& .suggestedUserBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "16px"
    },
    "& .suggestionLeftBox": {
        display: "flex",
        gap: "12px",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .followUnfollowBtn": {
        textTransform: "none",
        border: "1px solid #F5EA16",
        borderRadius: "50px",
        padding: "6px 16px"
    },
    "& .categoryTxt":{
        color:"#9695A1",
        fontSize:"16px",
        fontWeight:400,
        paddingLeft:"20px"
    },
    "& .headerCategoryBox":{
        paddingBottom:"10px",
        borderBottom:"1px solid #F2F3F6",
        marginTop:"40px"
    },
    "& .categoryInnerBox":{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginTop:"27px",
        marginLeft:"20px"
    },
    "& .categoriesBox":{
        marginTop:"14px"
    },
    "& .categoryTitleTxt":{
        fontSize:"12px",
        fontWeight:400,
        color:"#000000"
    }
});
const PostListContainer = styled(Box)({
    "& .postMainContainer": {
        height: "calc(100vh - 152px)",
        overflowY: "auto",
        scrollBehavior: "smooth",
    },
    "& .postContainer": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
})
// Customizable Area End
