Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";
exports.whatWeAreEndPoint = 'bx_block_landingpage/what_we_ares';
exports.aboutThePlateformEndPoint = 'bx_block_landingpage/about_the_platforms';
exports.performaceDataEndPoint = 'bx_block_dashboard/dashboards/dashboard_counts';
exports.categoriesEndPoint = 'bx_block_categories/categories';
exports.meetTheCreatersEndPoint = 'bx_block_landingpage/meet_the_creators';
exports.landingPageBanner = 'bx_block_landingpage/web_landing_pages';
exports.bottommImageEndPoint = 'bx_block_landingpage/footer_sections';
exports.getChooseCatergoriesDataEndPoint = 'bx_block_categories/categories';
exports.postchooseCategoriesDataEndPoint = 'bx_block_categories/categories/update_user_categories';
exports.subCategoriesDataEndPoint = 'bx_block_categories/sub_categories';
exports.getTopSearchEndPoint = 'bx_block_advanced_search/search?page=1&per_page=20&type=top&'
exports.getTopSearchLiveEventsEndPoint = 'bx_block_advanced_search/search?page=1&per_page=10&type=top'
exports.getShowsSearchedEndPoint = 'bx_block_advanced_search/search?page=1&per_page=10&type=shows'
exports.videoTutorialApiEndPoint = 'bx_block_video_management/video_managements';
exports.advanceSearchShowSearch='bx_block_advanced_search/search?page=1&per_page=10&type=shows&search=';
exports.advanceSearchTopSearch='bx_block_advanced_search/search?page=1&per_page=10&type=top&search=';
exports.advanceSearchProductSearch='bx_block_advanced_search/search?page=1&per_page=10&type=products&search=';
exports.sellerFaqApiEndPoint = "faqs?faq_for=seller"
exports.generalFaqApiEndPoint = "faqs?faq_for="
exports.aboutDataApiEndPoint = "bx_block_landingpage/abouts"
exports.privacyDataApiEndPoint = "privacy_policy?policy_for=general"
exports.termsDataApiEndPoint = "terms_and_condition?condition_for=general"
exports.sellerAnalyticsApiEndPoint = "seller_analytics"
exports.sellerProfileApiEndPoint= "account_block/get_seller_profile"
exports.sellerUpdateProfileApiEndPoint = "account_block/update_buyer_profile"
exports.BuyersearchResultfilterEndPoint = "bx_block_advanced_search/search_filter?page=1&per_page=10&search="
exports.favouriteItemEndPoint = "bx_block_favourite/favourites"
exports.delete = "DELETE"
exports.getAllNotifications = "bx_block_notifications/notifications";
exports.readAllNotifications = "bx_block_notifications/notifications/read_all";
exports.getSellerBanner = "bx_block_custom_ads/banners";
exports.selectAll = "Select All";
exports.productsTitle = "No Record was Found";
exports.productsSubTitle = "Uh-ho! it seems there's nothing in the wardrobe at the moment";
exports.liveEventsTitle = "No scheduled Live Events Found";
exports.liveEventsSubTitle = "Uh-ho! it seems there's no one live at the moment";
exports.getCategoriesEndPoint = "bx_block_categories/categories";
exports.getStoresEndPoint = "bx_block_landingpage/digital_mall/get_stores";
exports.getSellerProfilePercentageEndPoint = "/account_block/accounts/profile_completion_percentage";
exports.getOnOrderCollectionEndPoint="bx_block_advanced_search/search?type=top&on_order=true";
exports.createOnOrderEndPoint="bx_block_productdescription/on_order_requests";
exports.multiartFormDataApiContentType="multipart/form-data";
exports.getCartDataEndPoint = "bx_block_order_management/cart_items";

exports.goToFeedTxt = "GO TO FEED"
exports.sortTxt = "Sort"
exports.onOrderTxt = "On-order";
exports.orderDetailsTxt = "Order details";
exports.selectedItemTxt = "Selected Item";
exports.detailsTxt = "Details*";
exports.attachmentTxt = "Attachments";
exports.uploadImgTxt = "upload image";
exports.maxMbTxt = "max 5 mb";
exports.expectedDatetxt = "Expected delivery date";
exports.submitTxt = "Submit";
exports.requestSuccessTxt="Request successful";
exports.modalBodyTxt="You will be notified  on your registered email when request will be accepted";
exports.goToChatTxt="Go to chat";
exports.sendRequestTxt="Send Request";
exports.onOrderTagText="On order"
exports.getAllStoriesEndPoint = "/bx_block_posts/stories";
exports.viewStoryEndPoint = "/bx_block_posts/stories/user_stories?user_id={userId}";
// Customizable Area End