Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE"
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.postPostCreationEndPoint = "bx_block_posts/posts";
exports.getInventoryEndPoint = "bx_block_catalogue/catalogues/get_seller_catalogues";
exports.getTagUserEndPoint = "bx_block_livestreaming/live_streams/invite_users_list?role=seller";
exports.getPostListingDataEndPoint = "bx_block_posts/posts";
exports.postLikeUnlikeEndPoint = "bx_block_like/likes";

exports.categoriesTxt = "Categories";
exports.feedTxt = "Feed";
// Customizable Area End