
import React, { useEffect, useRef } from "react";
import {
  // Customizable Area Start
  Typography,
  Grid,
  Button,
  Box,
  AppBar,
  Toolbar,
  TextField,
  Modal,
  InputAdornment,
  Paper,
  Badge,
  Divider
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { withStyles } from '@material-ui/core/styles';
import { Link, NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover'
import { BlockComponent } from "../../framework/src/BlockComponent";

// import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch.web";

import {
  FlixoLogo,
  whiteSearchBar,
  user,
  notification,
  bag,
  BlackSearchIcon,
  notificationActive
} from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import StayTuned from "./StayTuned.web";
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from "../../framework/src/RunEngine";
import { configJSON } from "../../blocks/landingpage/src/LandingPageControllerWeb";
import { Message } from "../../framework/src/Message";
import eventEmitter from './StoreCount';
import { notificationDark } from "./assets";
import { baseURL } from "../../blocks/bulkuploading/src/BulkUploadingController";

interface Props {
  classes: any;
  navigation: any;
  onSearchChange: (value: any) => void;
  history: any;
  searchValue: string;
  // alertTwo:(value: any) => void;
}

interface S {
  isMenuOpen: boolean;
  isSearchExpanded: boolean;
  isLinkNone: boolean;
  isNotificationBoxOpen: boolean;
  isMenuListBoxOpen: boolean;
  searchValue: any;
  searchResultList: any;
  openTunedBox: boolean;
  buyerUserName: string;
  responseTopSearch: any;
  responseTopSearchproduct: any,
  filteredTitle: string[],
  finalSearchValue: any,
  isSuggestionClicked: boolean,
  accountListOpen: boolean
  accountListAnchorEl: any
  searchQuery: string;
  allNotifications: any,
  readNotificatons: any,
  unreadNotificationCount: number,
  tokenPresentOrNot:null | string
  cartCount:number
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area End

class AppHeader extends BlockComponent<
Props,
    S,
    SS> {
  searchValue: any;
  apiTopSearch: any;
  subScribedMessages: any;
  getAllNotifiction: any;
  readAllNotification: any;





  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.getSearchTopResultData = this.getSearchTopResultData.bind(this);
    this.getAllNotification = this.getAllNotification.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      isMenuOpen: false,
      isSearchExpanded: false,
      isLinkNone: false,
      isNotificationBoxOpen: false,
      isMenuListBoxOpen: false,
      searchValue: '',
      searchResultList: [],
      openTunedBox: false,
      buyerUserName: '',
      responseTopSearch: [],
      responseTopSearchproduct: [],
      filteredTitle: [],
      finalSearchValue: [],
      isSuggestionClicked: false,
      accountListOpen: false,
      accountListAnchorEl: null,
      searchQuery: '',
      readNotificatons: [],
      allNotifications: [],
      unreadNotificationCount: 0,
      tokenPresentOrNot:null,
      cartCount:0
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start


  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.apiTopSearch) {
     

      const liveStreamTitles = responseJson?.live_streams.data.map((item: any) => ({
        title: item.attributes.title,
        thumbnail: item.attributes.thumbnail,
      }));
      const productTitles = responseJson?.catalogues.data.map((item: any) => ({
        title: item.attributes.title,
        category: item.attributes.category.name,
        subCategory: item.attributes.sub_category.name,
        thumbnail: item.attributes.product_images && item.attributes.product_images.length > 0 ? item.attributes.product_images[0].url : null
      }));

      const allTitles = [...liveStreamTitles, ...productTitles];

      this.setState({ filteredTitle: allTitles });


      this.setState({ responseTopSearch: responseJson.live_streams.data });
      this.setState({ responseTopSearchproduct: responseJson.catalogues.data });
    
    }
    if (apiRequestCallId === this.getAllNotifiction) {
      if (responseJson && responseJson.data) {
        const allNotifications = responseJson.data;
        const unreadNotificationCount = allNotifications.filter((notification: any) => notification.attributes.is_read === false).length
        this.setState({ allNotifications, unreadNotificationCount });
      } 
    }
    if (apiRequestCallId === this.readAllNotification) {
      this.setState({ readNotificatons: responseJson });
      
    }
    // Customizable Area End
  }

  timer: any = null
  handleMenuToggle = () => {
    this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
  };
  closeMenu = () => {
    this.setState({ isMenuOpen: false });
  }
  openStayTunedBox = () => {
    this.setState({
      isMenuOpen: false,
      openTunedBox: true
    });
  }
  closeTunedBox = () => {
    this.setState({
      openTunedBox: false
    })
  }
  handleSearchExpand = () => {
    this.setState((prevState) => ({ isSearchExpanded: !prevState.isSearchExpanded }));
    if (this.state.isSearchExpanded) {
      this.setState({ isLinkNone: false, searchValue: "" })
    }
    else {
      this.setState({ isLinkNone: true })
    }
  };
  handleNotificationBoxToggle = () => {
    this.verifyTokenPresentOrNot()
    this.setState((prevState) => ({ isNotificationBoxOpen: !prevState.isNotificationBoxOpen }));
    this.getAllNotification();
    this.markAllNotificationsAsRead();
  };

  handleMenuListBoxToggle = () => {
    this.verifyTokenPresentOrNot()
    this.setState((prevState) => ({ isMenuListBoxOpen: !prevState.isMenuListBoxOpen }));
  };
  verifyTokenPresentOrNot=()=>{
    if(this.state.tokenPresentOrNot === null){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedLogin");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
      return
    }
  }

  handleSignUp=()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),"GetStartedSignup");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
      return
  }

  navigateUserProfile = () => {
    this.props.navigation.navigate('UserProfiles')
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValueclcik = event.target.value;
    this.setState({ searchValue: searchValueclcik });
    this.timer = setTimeout(() => {
      this.fetchSuggestions(searchValueclcik);
    }, 1000);
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      this.getSearchTopResultData();
      this.performSearch();
      this.props.history.push({
        pathname: '/Search',
        search: `?q=${encodeURIComponent(this.state.searchValue)}`,
        state: {
          data: encodeURIComponent(this.state.searchValue),
        },
      });
    }
  };

  fetchSuggestions = (searchValue: string) => {
    const partialMatches = this.state.filteredTitle.filter(
      (item: any) =>
        (item.title && typeof item.title === 'string' && item.title.toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.category && typeof item.category === 'string' && item.category.toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.subCategory && typeof item.subCategory === 'string' && item.subCategory.toLowerCase().includes(searchValue.toLowerCase()))
    );

    const uniqueCategories = new Set<string>();
    const uniqueSubCategories = new Set<string>();

    partialMatches.forEach((item: any) => {
      if (item.category && typeof item.category === 'string') {
        uniqueCategories.add(item.category.toLowerCase());
      }
      if (item.subCategory && typeof item.subCategory === 'string') {
        uniqueSubCategories.add(item.subCategory.toLowerCase());
      }
    });

    const uniqueCategoriesArray = Array.from(uniqueCategories);
    const uniqueSubCategoriesArray = Array.from(uniqueSubCategories);

    const finalSuggestions = partialMatches.map((item: any) => ({
      title: item.title,
      thumbnail: item.thumbnail,
      type: 'product'
    }));


    uniqueCategoriesArray.forEach((category: string) => {
      finalSuggestions.push({ title: category, thumbnail: null, type: 'category' });
    });

    uniqueSubCategoriesArray.forEach((subCategory: string) => {
      finalSuggestions.push({ title: subCategory, thumbnail: null, type: 'subCategory' });
    });

    this.setState({ finalSearchValue: finalSuggestions });
  };

  handleSearchButtonClick = () => {
    this.getSearchTopResultData();
    this.performSearch();
    this.timer = setTimeout(() => {
      this.props.history.push({
        pathname: '/Search',
        search: `?q=${encodeURIComponent(this.state.searchValue)}`,
        state: {
          data: encodeURIComponent(this.state.searchValue),
        },
      });
    }, 1000);
  };

  OpenAccountList = (event: any) => {
    this.setState({
      accountListAnchorEl: event.currentTarget,
      accountListOpen: !this.state.accountListOpen
    })
  }

  CloseAccountList = () => {
    this.setState({ 
      accountListAnchorEl: null,
      accountListOpen: !this.state.accountListOpen
     });
  };

  getSearchTopResultData = async () => {
    const tokenPresentOrNot = this.state.tokenPresentOrNot ?? null
    const headers = {
      "Content-Type": "application/json",
      token: tokenPresentOrNot
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTopSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.advanceSearchTopSearch}${this.state.searchValue}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.receive = this.receive.bind(this);
  }
  getAllNotification = async () => {
    const tokenPresentOrNot = this.state.tokenPresentOrNot??null
        const headers = {
      "Content-Type": "application/json",
      token: tokenPresentOrNot
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotifiction = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllNotifications}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.receive = this.receive.bind(this);
  }

  markAllNotificationsAsRead = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: await getStorageData('buerLoginToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readAllNotification = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.readAllNotifications}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.receive = this.receive.bind(this);
  }

  performSearch = () => {
    // Implement your search logic here
    const { searchValue } = this.state;
    const { responseTopSearch, responseTopSearchproduct } = this.state;
    const searchResults = this.state.filteredTitle.filter((item: any) => {
      return (
        item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        (item.category?.toLowerCase().includes(searchValue.toLowerCase()) || false) ||
        (item.subCategory?.toLowerCase().includes(searchValue.toLowerCase()) || false)
      );
    });
    this.setState({ finalSearchValue: searchResults })


    if (this.state.isSuggestionClicked) {
      // Handle the suggestion click
      this.setState({
        isSearchExpanded: false,
        isLinkNone: false,
      });
    } else {
      // Handle regular search
      const { searchValue } = this.state;
      const { responseTopSearch, responseTopSearchproduct } = this.state;
      // ... (rest of the code)
    }
  };

  getUserName = async () => {
    const username: any = await getStorageData("Buyer_Data");
    if (username) {
      const userObj = JSON.parse(username)
      this.setState({ buyerUserName: userObj.account.user_name });
    }
  }

  signOutAccount=()=>{
    const { history } = this.props;
    localStorage.clear();
    history.push('/GetStartedLogin');
  }
  async componentDidMount() {
    const tokenPresentOrNot =  await getStorageData('buerLoginToken')
    const cartCount= await getStorageData("cartItemsCount")
    this.setState({ tokenPresentOrNot: tokenPresentOrNot, cartCount: Number(cartCount) })
    eventEmitter.on('cartUpdated', (count) => {
      this.setState({ cartCount: count });
    });
  
    if (!this.state.buyerUserName) {
      this.getUserName()
    }
    this.getSearchTopResultData();
    this.getAllNotification();   
  }
 
   navigateToMessages = () =>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ChatScreen");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
   }

  renderNotificationIcon = (isNotificationOpen: boolean) => {
    if (isNotificationOpen) {
      return <img src={notificationActive} alt="" className="notificationIcon" />
    } else {
      return <img src={notification} alt="" className="notificationIcon"  />
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { isMenuOpen, isSearchExpanded, isNotificationBoxOpen, buyerUserName, isMenuListBoxOpen ,tokenPresentOrNot} = this.state;
    const { history } = this.props;
    const { finalSearchValue, searchQuery } = this.state;
    const { unreadNotificationCount } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <section>
          <AppBar className={classes.AppBarBox}>
            <Toolbar>
              <Grid container spacing={2}>
                 <><Grid item xs={3} lg={2}>
                    <Box>
                      <Button className={classes.appLogo} component={Link} to={'/'}>
                        <img src={FlixoLogo} alt="" />
                      </Button>
                    </Box>
                  </Grid>

                    <Grid item xs={9} lg={10}>
                      <Box className={classes.headerMenuRight}>
                        <div className={classes.mobileMenu}>
                          <label htmlFor="toggle-nav" className="menu-icon" onClick={this.handleMenuToggle}>
                            <MenuIcon />
                          </label>
                          <TextField type="checkbox" id="toggle-nav" />
                        </div>
                        <Box
                          className={`${classes.headerLinkBox} ${isMenuOpen ? 'checked' : ''}`}
                          sx={{ display: 'flex', alignItems: 'center', gridGap: '60px' }}>
                          <Box className={classes.headerButtonLinks}>
                          <Box className={classes.signUpbtn}>
                           {tokenPresentOrNot === null && <Button data-test-id="signup" className={classes.singUpBtn} onClick={this.handleSignUp}>SignUp/Login</Button>} 
                          </Box>
                            <Button className={classes.headerLink} exact activeClassName={classes.activeLink}
                              component={NavLink} to={'/'} onClick={this.closeMenu}>
                              Home
                            </Button>
                            <Button activeClassName={classes.activeLink} className={classes.headerLink} component={NavLink} to={'/Cfdigitalmall'} onClick={this.closeMenu}>
                              Digital Mall
                            </Button>
                            <Button activeClassName={classes.activeLink} className={classes.headerLink} component={NavLink} to={'/ChatScreen'} onClick={this.closeMenu}>
                              Messages
                            </Button>
                            <Button className={classes.headerLink} onClick={this.openStayTunedBox}>
                              Become a seller
                            </Button>
                            {this.state.isLinkNone === false ?
                              <>
                                <Button className={classes.headerLink} onClick={this.openStayTunedBox}>
                                  Become a content creator
                                </Button>
                                <Button className={classes.headerLink} onClick={this.openStayTunedBox}>
                                  Invite friends
                                </Button></>
                              : <p></p>
                            }
                            <StayTuned openTunedBox={this.state.openTunedBox} closeTunedBox={this.closeTunedBox} />
                          </Box>
                        </Box>
                        <Box className={classes.headerSearchBox}>
                          <Box className={classes.headerMenuRight} style={{ color: 'white' }}>
                            <Box
                              className={classes.clickableSearch}
                              onClick={this.handleSearchExpand}
                            >
                              <img src={whiteSearchBar} />
                            </Box>
                            <Modal
                              open={isSearchExpanded}
                              aria-labelledby="modal-title"
                              aria-describedby="modal-description"
                              style={{ backdropFilter: 'blur(5px)' }}
                            >
                              <Box
                                style={{
                                  backgroundColor: '#ffffff', height: '100px', position: 'absolute',
                                  top: '72px',
                                  left: '0%',
                                  width: '100%'
                                }}
                              >
                                <Box>
                                  <TextField
                                    placeholder="Search FlixooLive"
                                    value={this.state.searchValue}
                                    onChange={this.handleSearchChange}
                                    onKeyPress={this.handleKeyPress}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start" style={{ cursor: 'pointer' }} onClick={this.handleSearchButtonClick}>
                                          <img src={BlackSearchIcon} alt="Search Icon" />
                                        </InputAdornment>
                                      ),
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Button onClick={this.handleSearchExpand} size="small">
                                            <CloseIcon />
                                          </Button>
                                        </InputAdornment>
                                      ),
                                    }}
                                    className={classes.expandedSearch}
                                  />
                                  <Box
                                    style={{ border: '1px solid rgba(0,0,0,0.4)', width: '56%', height: '1px', margin: '0 auto' }}>
                                  </Box>
                                </Box>
                                {finalSearchValue.length > 0 ? (
                                  <Box style={{ maxWidth: '940px', backgroundColor: 'white', margin: '35px auto', padding: 15, overflowX: "auto" }}>
                                    {finalSearchValue.map((item: any) => {
                                      const isMatch = item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        (item.category && item.category.toLowerCase().includes(searchQuery.toLowerCase())) ||
                                        (item.subCategory && item.subCategory.toLowerCase().includes(searchQuery.toLowerCase()));

                                      if (isMatch) {
                                        return (
                                          <div
                                            key={item.id}
                                            style={{ paddingTop: 10, paddingBottom: 10, borderBottom: "1px solid #CCC", cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                            onClick={() => {
                                              if (item.subCategory) {
                                                history.push(`/Search?q=${encodeURIComponent(item.subCategory)}`);
                                              } else if (item.category) {
                                                history.push(`/Search?q=${encodeURIComponent(item.category)}`);
                                              } else {
                                                history.push(`/Search?q=${encodeURIComponent(item.title)}`);
                                              }
                                            }}
                                          >
                                            {item.thumbnail && item.thumbnail.startsWith('https://') ? (
                                              <img src={item.thumbnail} alt="Thumbnail" style={{ paddingRight: "10px", width: "35px", height: "34px", borderRadius: '4px' }} />
                                            ) : (
                                              <img src={BlackSearchIcon} alt="Placeholder" style={{ marginRight: "10px", width: "24px", height: "24px" }} />
                                            )}
                                            <p>{item.title}</p>
                                          </div>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                  </Box>
                                ) : (
                                  <></>
                                )}

                              </Box>

                            </Modal>

                          </Box>
                        </Box>
                        <Box className={classes.headerCartBox} onClick={this.handleNotificationBoxToggle}>
                          {unreadNotificationCount > 0 ? (
                            <Badge variant="dot" color="secondary">
                              {this.renderNotificationIcon(isNotificationBoxOpen)}
                            </Badge>
                          ) : (
                             this.renderNotificationIcon(isNotificationBoxOpen)
                          )}
                          {isNotificationBoxOpen && (
                            <NotificationBox closeNotificationBox={this.handleNotificationBoxToggle} allNotifications={this.state.allNotifications} markAllAsRead={this.markAllNotificationsAsRead} />
                          )}
                        </Box>
                        <Button className={classes.navLinkBtn} component={Link} to={'/BuyerMultipleCart'}>
                          <img src={bag} alt="" />
                          <span className={classes.cartCountstyle} style={{ display: this.state.cartCount > 0 ? 'flex' : 'none' }}>{this.state.cartCount}</span>
                        </Button>
                        <Box className={classes.profileIconBox} onClick={this.handleMenuListBoxToggle} style={{ cursor: 'pointer', textDecoration: 'none', display: "flex", alignItems: "center", gridGap: '7px' }} >
                          <img src={user} alt="" />
                          <Typography variant="h5">
                            {buyerUserName}
                          </Typography>
                          {isMenuListBoxOpen && <MenuListBox closeMenuListBox={this.handleMenuListBoxToggle} openStayTuned={this.openStayTunedBox} navigation={this.props.navigation} />}
                        </Box>
                        <Box>
                       {tokenPresentOrNot === null && <Button data-test-id="signup" className={classes.singUpButton} onClick={this.handleSignUp}>SignUp/Login</Button>} 
                        </Box>
                      </Box>
                    </Grid>
                  </>
              </Grid>
            </Toolbar>
          </AppBar>
        </section>
      </>
      // Customizable Area End
    );
  }
}


// Customizable Area Start

interface NotificationBoxProps {
  closeNotificationBox: () => void;
  allNotifications: any[];
  markAllAsRead: () => void;
}

interface MenuListBoxProps {
  closeMenuListBox: () => void;
  navigation: any
  openStayTuned: any
}

const OfferBox = ({ notificationData }: { notificationData: Array<any> }) => {
  return (
    <>
      {notificationData.map((notification: any, index: number) => (
        <Box key={index} style={{ backgroundColor: notification.attributes.is_read ? 'transparent' : '#E3E3E3' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: '10px', padding: '10px 15px' }}>
            <Box style={{
              minWidth: '50px',
              minHeight: '50px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <span style={{backgroundColor:'#E3E3E3',borderRadius: '50%',padding:'15px'}}>
                <img src={notificationDark} alt="" />
              </span>
            </Box>
            <Box>
              <span style={{ color: '#000000', fontWeight: notification.attributes.is_read ? 'normal' : 'bold' }}>{notification.attributes.contents}</span>
            </Box>
          </Box>
          {index !== notificationData.length - 1 && (
            <Box style={{ border: '1px solid rgba(0,0,0, 0.1)', width: '90%', height: '1px', margin: '15px auto' }}></Box>
          )}
        </Box>
      ))}
    </>
  );
};

const NotificationBox: React.FC<NotificationBoxProps> = ({ closeNotificationBox, allNotifications }) => {

  const notificationBoxRef = useRef<HTMLDivElement | null>(null);;

  useEffect(() => {
    // Attach a click event listener to the document body
    const handleClickOutside = (event: any) => {
      if (notificationBoxRef.current && !notificationBoxRef.current.contains(event.target)) {
        closeNotificationBox();
      }
    };

    // Add the event listener
    document.body.addEventListener('click', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [closeNotificationBox]);

  return (
    <Paper
      ref={notificationBoxRef}
      style={{ backgroundColor: '#ffffff' }}
      className="NotificationBox"
    >
      <Box style={{ paddingBottom: '15px' }}>
        <Box style={{ backgroundColor: '#F5EA16', height: '87px', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', }}>
          <h6 style={{ color: '#000000', margin: '0px', padding: '35px 25px', fontSize: '16px', lineHeight: '24px' }} >Notifications</h6>
        </Box>
        <Box className="notificationDataBox">
          <OfferBox notificationData={allNotifications} />
        </Box>
      </Box>
    </Paper>
  );
};

const MenuListBox: React.FC<MenuListBoxProps> = ({ closeMenuListBox, openStayTuned, navigation }) => {

  const MenuListBoxRef = useRef<HTMLDivElement | null>(null);;

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (MenuListBoxRef.current && !MenuListBoxRef.current.contains(event.target)) {
        closeMenuListBox();
      }
    };
    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [closeMenuListBox]);

  const handleSignOut = () => {
    try {
      removeStorageData("buerLoginToken")
      localStorage.removeItem('authToken');
      localStorage.removeItem('Buyer_Data');
      localStorage.removeItem("cartItemsCount");
      sessionStorage.clear();
      window.location.replace("/");
      history.pushState(null, "/");
      window.onpopstate = () => {
        history.pushState(null, "/GetStartedLogin");
      };
    }
    catch (error) {
      console.error("Failed to signout", error);
    }
  }

  return (
    <Paper
      ref={MenuListBoxRef}
      style={{ backgroundColor: '#ffffff' }}
      className="MenuListBox"
    >
      <Box style={{ paddingBottom: '15px' }}>
        <Box style={{ backgroundColor: '#F5EA16', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', }}>
          <h6 style={{ color: '#000000', margin: '0px', padding: '16px 24px', fontSize: '16px', lineHeight: '24px' }} >Menu</h6>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ fontSize: '16px', fontWeight: 600, padding: '12px 24px' }}>Buyer</Typography>
          <Divider />
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={"/Wishlist"} >Wishlist</Link>
          <Typography onClick={openStayTuned} style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }}>Offers</Typography>
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={"/CustomisedOrderStatus"}>My Orders</Link>
          <Divider />
          <Typography style={{ fontSize: '16px', fontWeight: 600, padding: '12px 24px' }}>Account</Typography>
          <Divider />
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={'/UserProfiles'}>My Profile</Link>
          <Typography onClick={openStayTuned} style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }}>Settings</Typography>
          <Typography onClick={openStayTuned} style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }}>Subscription</Typography>
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={"/BuyerFaq"}>FAQs</Link>
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={"/BuyerPrivacy"}>Privacy</Link>
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={"/BuyerTerms"}>Terms & Conditions</Link>
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={"/HelpCentre"}>Help & Support</Link>
          <Link style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer', color: 'black', textDecoration: 'none' }} to={"/BuyerAbout"}>About</Link>
          <Typography style={{ fontSize: '16px', padding: '4px 48px', cursor: 'pointer' }} onClick={handleSignOut}>Sign Out</Typography>
        </Box>
      </Box>
    </Paper>
  );
};


const styles = (theme: any) => ({
  AppBarBox: {
    height: '103px',
    backgroundColor: "#000000",
    '& .MuiToolbar-regular': {
      margin: 'auto 0',
    },
    '& #toggle-nav': {
      display: 'none',
    },
    '& .menu-icon': {
      display: 'none'
    },
    '& .HeaderGetStartedBtn': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down(1150)]: {
      height: '70px',
      '& #toggle-nav': {
        display: 'block',
      },
      '& .menu-icon': {
        display: 'block',
      },
      '& #toggle-nav:checked ~ .headerLinkBox': {
        left: '0%',
      },
    },
    '@media (max-width: 600px)': {
      '& .MuiToolbar-gutters':{
        paddingLeft:"8px",
      paddingRight:"8px"
      }
    },
  },

  headerSearchBox: {
    '& .MuiInputBase-root': {
      height: '35px',
      [theme.breakpoints.down('xs')]: {
        height: '32px',
      },
    },
    '& .MuiTextField-root': {
      backgroundColor: '#000000',
      borderRadius: '8px',
    },
    '& img': {
      marginLeft: '5px',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0px',
    },
    '& .MuiOutlinedInput-input': {
      color: '#ffffff',
      padding: '0px 0px 0px 10px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',
        lineHeight: '7px',
      },
    },
    '& .MuioutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedoutline': {
        border: 'none !important',
      },
    },

  },
  headerLinkBox: {
    display: 'flex',
    gridGap: '50px',
    transition: 'left 0.3s ease',
    '& a': {
      color: 'white',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.3px',
      textTransform: 'initial',
      opacity: '0.8',
      fontFamily: 'Poppins-Medium',
    },
    [theme.breakpoints.down(1150)]: {
      position: 'absolute' as const,
      top: '67px',
      left: '-106%',
      backgroundColor: '#000000',
      width: '100%',
      height: ' 100vh',
      padding: '20px',
      transition: '.3s ease',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '&.checked': {
        left: '0%',
        padding: '0px',
      },
      '& a': {
        lineHeight: '30px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      left: '-111%',
      top: '63px',
    }
  },
  appLogo: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
      '& img': {
        maxHeight: '48px',
      },
    },
  },
  headerButtonLinks: {
    display: 'flex',
    height: '32px',
    whiteSpace: 'nowrap' as const,
    gap: '15px',
    [theme.breakpoints.down(1500)]: {
      gap: '0px'
    },
    [theme.breakpoints.down(1150)]: {
      flexDirection: 'column' as const,
      justifyContent: 'center',
    },
  },
  headerCartBox: {
    cursor: 'pointer',
    '& img': {
      width: '24px',
      height: '24px',
    },
    '& .NotificationBox': {
      position: "absolute",
      top: "70px",
      right: "280px",
      maxWidth: "375px",
      borderRadius: "20px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
      zIndex: 1,
      [theme.breakpoints.down(1150)]: {
        top: "50px",
        right: "140px",
      },
      [theme.breakpoints.down('xs')]: {
        top: '70px',
        right: '6px',
        marginLeft: '10px',
      },
    },
    '& .notificationDataBox': {
      scrollbarWidth: "none",
      marginTop: '20px',
      maxHeight: '465px',
      overflowY: 'scroll',
    },
    "& .notificationDataBox::-webkit-scrollbar ": {
      display: "none",
    },
    "& .notificationIcon":{
      cursor:"pointer",
    }
  },
  navLinkBtn:{
    minWidth: "24px",
    padding: "0",
    "& img": {
      width: "24px",
      height: "24px",
    },
  },
  profileIconBox: {
    backgroundColor: '#ffffff',
    color: '#000000',
    padding: '8px 12px 8px 12px',
    borderRadius: '4px',
    '& h5': {
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.3',
    },
    [theme.breakpoints.down(1150)]: {
      padding: '5px',
      borderRadius: '50%',
      '& h5': {
        display: 'none',
      },
    },
    '& .MenuListBox': {
      position: "absolute",
      top: "70px",
      right: "50px",
      maxWidth: "265px",
      width: '100%',
      borderRadius: "20px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
      zIndex: 1,
      [theme.breakpoints.down(1150)]: {
        top: "50px",
        right: "140px",
      },
      [theme.breakpoints.down('xs')]: {
        top: '70px',
        right: '6px',
        marginLeft: '10px',
      },
    },
  },
  headerMenuRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridGap: '30px',
    height: '100%',
    [theme.breakpoints.down(1500)]: {
      gridGap: '10px',
    },
  },
  mobileMenu: {
    [theme.breakpoints.down(1150)]: {
      width: '34px',
      height: '34px',
      order: 5 as const,
      '& svg': {
        width: '34px',
        height: '34px',
      },
      '& .MuiFormControl-root': {
        opacity: '0',
      },
    },
  },
  HeaderGetStartedBtn: {
    '& a': {
      fontFamily: 'Poppins-Regular',
      width: '107px',
      height: '32px',
      maxWidth: '100%',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.3px',
      textTransform: 'capitalize',
    },
  },
  expandedSearch: {
    width: "100%", // Expand to full width
    transition: "width 0.3s ease", // Add a smooth transition
    '& .MuiInput-root': {
      width: '62%',
      margin: '0 auto',
      paddingTop: '30px',
      paddingLeft: '20px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  // Define styles for the collapsed search bar
  collapsedSearch: {
    width: "24px", // Initial width when collapsed
    transition: "width 0.3s ease", // Add a smooth transition
  },
  activeLink: {
    color: '#ffffff !important' as const,
    fontWeight: 600,
    opacity: '1 !important' as const,
  },
  headerLink: {
    color: '#ffffff',
    opacity: '0.8',
    fontSize: '14px',
    textTransform: 'none' as const
  },
  menuDetails: {
    backgroundColor: '#fff',
    width: '268px',
    borderRadius: '10px',
    overflow: 'auto',
    '@media (max-width: 600px)': {
      width: '240px',
  },
  },
  menuDiv: {
    height: '61px',
    backgroundColor: '#F5EA16',
    display: 'flex',
    alignItems: 'center'
  },
  menuText: {
    color: '#000',
    fontFamily: 'Poppins !important',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginLeft: '36px',
  },
  buyerTitle: {
    padding: '12px 36px',
    borderBottom: '1px solid #E0E0E0',
  },
  accountTitle: {
    borderBottom: '1px solid #E0E0E0',
    borderTop: '1px solid #E0E0E0',
    padding: '12px 36px',
  },
  buyerText: {
    color: '#000',
    fontFamily: 'Poppins !important',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  buyerMenu: {
    padding: '12px 60px',
  },
  buyerMenuItemFirst: {
    color: '#000',
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '18px',
    textDecoration: 'none'
  },
  singUpButton:{
    fontFamily: 'Poppins !important',
    background: '#F5EA16',
    borderRadius:"10px",
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    pointer:"cursor",
    textTransform:"none" as const,
    "&:hover":{
        background: '#F5EA16',
    },
    '@media (max-width: 600px)': {
      display: 'none',
    },
},
  buyerMenuItemLast:{
    color: '#000',
    fontFamily: 'Poppins !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '12px',
    marginBottom: '30px',
    textDecoration: 'none'
  },
  buyerMenuItem: {
    color: '#000',
    fontFamily: 'Poppins, !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    marginTop: '12px'
  },
  clickableSearch:{
    '@media (max-width: 600px)': {
      display:"none"
    },
  },
  signUpbtn:{
    display:"none",
    '@media (max-width: 600px)': {
      display:"block",
      alignSelf:"center"
    },
  },
  singUpBtn:{
    fontFamily: 'Poppins !important',
    background: '#F5EA16',
    borderRadius:"10px",
    color: '#444',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    pointer:"cursor",
    textTransform:"none" as const,
    display:"none",
    "&:hover":{
        background: '#F5EA16',
    },
    '@media (max-width: 600px)': {
      display: 'block',
    },
},
cartCountstyle: {
  position: "absolute",
  top: "-5px",
  right: "-10px",
  backgroundColor: "red",
  color: "white",
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
  // display:"none"
},
});

// export default withStyles(styles)(AppHeader);
//@ts-ignore
export default withStyles(styles)(withRouter(AppHeader));
// Customizable Area End