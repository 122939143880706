export const FlixoLogo = require("../assets/FlixoLogo.svg");
export const FlixooLogo = require("../assets/FlixooLogo.png");
export const searchBar = require("../assets/searchBar.svg");
export const shoppingCart = require("../assets/shoppingCart.svg");
export const user = require("../assets/user.svg");
export const faceBook = require("../assets/faceBook.svg");
export const instagram = require("../assets/instagram.svg");
export const twitter = require("../assets/twitter.svg");
export const bag = require("../assets/bag-2.svg");
export const whiteSearchBar = require("../assets/whiteSearchBar.png");
export const notification = require("../assets/notification.svg");
export const BlackSearchIcon = require("../assets/search-normal.jpeg");
export const notificationDark = require("../assets/notificationDark.png")
export const sucessImageIcon  = require("../assets/sucessImageIcon.png")
export const leftArrow  = require("../assets/leftArrow.svg")
export const bellIcon = require("../assets/bellIcon.svg")
export const flixooLoader = require("../assets/FlixooLoader.json")
export const notificationActive = require("../assets/notificationActive.svg");
export const heartIcon = require("../assets/HeartImg.svg");
export const commentIcon = require("../assets/commentIcon.svg");
export const userAdd = require("../assets/ConnectPerson.svg");